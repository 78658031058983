<template>
  <dashPage :side-user="!SM"
            :mobileTitle="$t('Lecture')"
            :mobileSideTitle="$t('Details')"
            :header-route="businessDashLink('courses/' + entity.course_id + '/student' )"
            icon="mdi-chevron-left">

    <template #defaultDesktop>

      <div v-if="displayEntity && entity.topics.length > 0">

          <!--    Header Title And Navigation-->
          <div v-if="!SM"
               class="d-flex justify-space-between mb-6">
            <div class="d-flex align-center">
              <v-btn :to="businessDashLink('courses/' + entity.course_id + '/student' )" outlined  icon class="mr-3" :color="wsACCENT">
                <v-icon  >mdi-chevron-left</v-icon>
              </v-btn>

              <div>
                <h4 >{{ entity.name}}</h4>
                <h2 ref="topicName">{{ entity.topics[selectedTopic].name }}</h2>
              </div>

            </div>

          </div>

          <!-- Topic content-->
          <v-sheet class="wsRoundedHalf d-flex justify-center  mt-6">
            <div style="max-width: 760px; width: 100%"  >

              <wsTextViewer
                  :value="entity.topics[selectedTopic].content"
                  :html="entity.topics[selectedTopic].text_content"
                  :course-id="entity.course_id"
                  student
                  class="pb-6"
              />

              <div class="d-flex justify-space-between">
                <div  style="width: 100%">

                  <v-btn  v-if="selectedTopic > 0"
                          @click="selectedTopic--; scrollTop()"
                          :color="wsDARK"
                          class="noCaps "
                          text>
                    <v-icon>mdi-chevron-left</v-icon>
                    {{ $t('GoBack') }}
                  </v-btn>

                </div>
                <div style="width: 100%" class="d-flex justify-end">
                  <v-btn v-if="selectedTopic < entity.topics.length -1 "
                         @click="selectedTopic++; scrollTop()"
                         :color="wsDARK"
                         class="noCaps"
                         text>
                    {{ $t('Next') }}
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>

          </v-sheet>

      </div>
      <div v-else>
        <!--    Header Title And Navigation-->
        <div v-if="displayHide('sm')"
             class="d-flex justify-space-between mb-6">
          <div class="d-flex align-center">
            <v-btn :to="businessDashLink('courses/' + entity.course_id + '/student' )" outlined  icon class="mr-3" :color="wsACCENT">
              <v-icon  >mdi-chevron-left</v-icon>
            </v-btn>

            <div>
              <h4 >{{ entity.name}}</h4>
              <h5 class="font-weight-medium">{{ $t('Lecture') }}</h5>
            </div>

          </div>


        </div>
      </div>

      <div v-if="!displayEntity">
        <h2 class="font-weight-light"> {{ $t('NoRightsToViewContent') }}</h2>
        <v-btn :to="businessDashLink('courses/student' )" outlined block  class="noCaps mr-3 mt-6" :color="wsACCENT">
          <v-icon  >mdi-chevron-left</v-icon>
          {{ $t('BackToCourses') }}
        </v-btn>
      </div>

    </template>

    <template #defaultMobile>
      <v-divider />
        <h5 class="mt-3 text-center " v-html="entity.name"></h5>
        <h5 v-if="entity.topics.length > 0" class="mt-3 text-center ">{{ $t('Topic') }}: <span v-html="entity.topics[selectedTopic].name"></span>  </h5>
        <v-sheet class="wsRoundedHalf d-flex justify-center pa-1 my-4" >
          <div style="width: 100%"   >

            <wsTextViewer
                v-if="entity.topics.length > 0"
                :value="entity.topics[selectedTopic].content"
                :html="entity.topics[selectedTopic].text_content"
                :course-id="entity.course_id"
                student
                class="pb-6"
            />

            <div class="d-flex justify-space-between">
              <div  style="width: 100%">
                <v-btn  v-if="selectedTopic > 0"
                        @click="selectedTopic--; scrollTop()"
                        :color="wsDARK"
                        class="noCaps "
                        text>
                  <v-icon>mdi-chevron-left</v-icon>
                  {{ $t('GoBack') }}
                </v-btn>

              </div>
              <div style="width: 100%" class="d-flex justify-end">
                <v-btn v-if="selectedTopic < entity.topics.length -1 "
                       @click="selectedTopic++; scrollTop()"
                       :color="wsDARK"
                       class="noCaps"
                       text>
                  {{ $t('Next') }}
                  <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>

        </v-sheet>
    </template>

    <template #default.after>
      <component-navigation
          :entity="entity"
          lecture
      />
    </template>


    <template v-slot:side="{collapse}">

      <!-- Lecture Side Header-->
      <div v-if="!SM" class="d-flex justify-space-between align-center">
        <h3>{{ $t('Lecture') }} </h3>
      </div>

      <!-- Lecture Topic-->
      <h5 v-if="!SM" class="mt-6">{{ $t('Topics') }}</h5>
      <h4 v-if="SM"  class="mt-6 ml-3 text-center">{{ $t('Topics') }}</h4>
      <v-list color="transparent" dense class="pt-3">
        <v-list-item-group mandatory v-model="selectedTopic"  >
          <div v-for="(item,i) in entity.topics"  :key="i">
            <v-list-item color="white" @click="scrollTop(); collapse()" >
              <v-list-item-title>{{ i+1 }}. <span v-html="item.name"></span></v-list-item-title>
            </v-list-item>
          </div>
        </v-list-item-group>
      </v-list>
      <!-- Lecture Files-->
      <div v-if="entity.files.length > 0">
        <h5 v-if="!SM" class="mt-6">{{ $t('Files') }} :</h5>
        <h4 v-if="SM"  class="mt-6 ml-3 text-center">{{ $t('Files') }}</h4>
        <v-simple-table class="wsHoverDarker" :style="`background-color: ${wsDARKLIGHT}`"
                        :dense="!SM">
          <tbody>
          <tr @click="collapse()" v-for=" (file,i) in entity.files "  :key="i + 'file'" class="pointer">
            <td width="10px"><v-icon small>mdi-file</v-icon></td>
            <td @click="openFile(file)" class="pl-0">
              <h5 :class="!SM ? 'font-weight-light' : 'font-weight-medium'">{{ file.name }}</h5>
            </td>
          </tr>
          </tbody>
        </v-simple-table>
      </div>


    </template>


    <!-- Display Photo Dialog-->
    <v-dialog v-model="displayImage"
              fullscreen>
      <v-sheet :color="wsBACKGROUND" class="fill-height">
        <div class="d-flex justify-space-between py-2 px-3 align-center">
          <h6>{{ selectedImage.name }}</h6>
          <div>
            <v-btn v-if="!SM" small :href="selectedImage.src" class="noCaps mr-3" :color="wsACCENT" outlined download type="attachment" target="_self">
              <v-icon small>mdi-download</v-icon>
              {{$t('Download')}}
            </v-btn>
            <v-btn :small="SM" icon @click="displayImage = false"><v-icon :small="SM" >mdi-close</v-icon></v-btn>
          </div>
        </div>
        <v-btn v-if="SM" block small :href="selectedImage.src" class="noCaps mb-3" :color="wsACCENT" text download type="attachment" target="_self">
          <v-icon small>mdi-download</v-icon>
          {{$t('Download')}}
        </v-btn>
        <v-img transition="xxx" :src="selectedImage.src" />
      </v-sheet>
    </v-dialog>

  </dashPage>
</template>

<script>
import {mapActions} from "vuex";
import componentNavigation from "@/components/pages/westudy/student/StudentCourse/UI/componentNavigation";

export default {
  name: "studentLecture",
  props : ["uuid"],
  components : {
    componentNavigation
  },
  data() {
    return {
      entity : {
        topics : [],
        files : []
      },
      selectedImage : {
        src : '',
        name : ''
      },
      displayEntity : true,
      displayVideo : false,
      displayImage : false,
      selectedTopic : 0,
    }
  },
  methods : {
    ...mapActions('courses', [
      'GET_LECTURE_STUDENT'
    ]),
    ...mapActions('upload', [
      'GET_STUDENT_COMPONENT_FILE'
    ]),
    scrollTop() {
      this.$nextTick(() => {
        var container = this.$el.querySelector("#dashPageContent");
        container.scrollTop = 0;
      } );
    },
    //technical
    async openFile(file) {
      let data =  {
        file : file.uuid,
        course : this.entity.course_id
      }
      let result = await this.GET_STUDENT_COMPONENT_FILE(data)
      if (!result) { return this.notify('File Not found') }
      var a = document.createElement('a');
      a.href = result.url;
      if (result.mime.includes('image')) {
        this.displayImage = true
        this.selectedImage = {
          src : result.url,
          name : file.name
        }
      } else {
        a.download = file.name;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }

    },

    async initPage() {

      if ( !this.uuid ) { return }

      let result = await this.GET_LECTURE_STUDENT(this.uuid)
      if (!result) {
        return this.displayEntity = false
      }

      this.entity = result.lecture


    },
  },
  mounted() {

    this.initPage()
  }
}
</script>

<style scoped>

</style>